type ScreenSizeName = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
type ExtendedScreenSizeName =
  | 'xs'
  | 'sm'
  | 'base'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'

const screenSizeNames: ScreenSizeName[] = ['xs', 'sm', 'md', 'lg', 'xl']

export const PIXELS_PER_REM = 16

export const screens: Record<ScreenSizeName, string> = {
  xs: '480px',
  sm: '768px',
  md: '940px',
  lg: '1200px',
  xl: '1600px',
}

export const fontSizes: Record<ExtendedScreenSizeName, string> = {
  xs: '0.75rem',
  sm: '0.875rem',
  base: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.375rem',
  '3xl': '1.75rem',
  '4xl': '2.125rem',
}

const colorSafelist = ['pageBackground', 'pageText']

const spacingSafelist = [
  0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 16, 20, 24, 32, 40, 48, 56, 64,
].map((spacing) => `${spacing}`)

const generateClasses = (
  property: string,
  values: string[],
  responsive = true,
  negative = false,
) => {
  const classes: string[] = []

  for (const value of values) {
    const c = `${property}-${value}`
    classes.push(c)

    if (negative) {
      classes.push(`-${c}`)
    }

    if (responsive) {
      for (const screenSizeName of screenSizeNames) {
        classes.push(`${screenSizeName}:${c}`)

        if (negative) {
          classes.push(`-${screenSizeName}:${c}`)
        }
      }
    }
  }

  return classes
}

export const safelist = [
  ...generateClasses('text', ['left', 'center', 'right', 'justify']),
  ...generateClasses('animate', ['none', 'spin', 'ping', 'pulse', 'bounce']),
  ...generateClasses('max-w', [
    '0',
    'none',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    '5xl',
    '6xl',
    '7x',
    'full',
    'min',
    'max',
    'prose',
    'screen-sm',
    'screen-md',
    'screen-lg',
    'screen-xl',
    'screen-2xl',
  ]),
  ...generateClasses('min-h', ['screen', 'xs', 'sm', 'md', 'lg', 'xl']),
  ...generateClasses('pt', [
    '[100%]',
    '[calc((3/4)*100%)]',
    '[calc((7/5)*100%)]',
    '[calc((6/4)*100%)]',
    '[calc((9/16)*100%)]',
    '[calc((7/16)*100%)]',
  ]),
  ...generateClasses('pt', spacingSafelist, true),
  ...generateClasses('pb', spacingSafelist, true),
  ...generateClasses('pr', spacingSafelist, true),
  ...generateClasses('pl', spacingSafelist, true),
  ...generateClasses('mt', spacingSafelist, true),
  ...generateClasses('mb', spacingSafelist, true),
  ...generateClasses('mr', spacingSafelist, true),
  ...generateClasses('ml', spacingSafelist, true),
  ...generateClasses('text', colorSafelist),
  ...generateClasses('bg', colorSafelist, true),
  ...generateClasses('before:bg', colorSafelist, true),
  ...generateClasses('after:bg', colorSafelist, true),
]

export const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000000',
  white: '#ffffff',
  gray: {
    dark: '#171717',
    light: '#f4f4f4',
  },
}

export const semanticColors = {
  error: '#dc2626',
  success: '#16a34a',
  pageBackground: 'rgb(var(--color-background) / <alpha-value>)',
  pageText: 'rgb(var(--color-text) / <alpha-value>)',
}
