import { type ReactNode, createContext } from 'react'

interface ProductContextProps {
  currency?: string
}

interface ProductContextProviderProps {
  currency?: string
  children: ReactNode
}

export const ProductContext = createContext<ProductContextProps>({})

export const ProductContextProvider = ({
  currency,
  children,
}: ProductContextProviderProps) => {
  return (
    <ProductContext.Provider
      value={{
        currency,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
