import { type ReactNode, createContext, useCallback } from 'react'
import { useRouter } from 'next/router'

import { type Locale, defaultLocale } from './language'

interface LanguageContextProps {
  locale: Locale
  changeLanguage: (newLocale: Locale) => void
}

const initialContext: LanguageContextProps = {
  locale: defaultLocale,
  changeLanguage: () => null,
}

export const LanguageContext =
  createContext<LanguageContextProps>(initialContext)

interface LanguageContextProviderProps {
  locale: Locale
  children: ReactNode
}

export const LanguageContextProvider = ({
  locale,
  children,
}: LanguageContextProviderProps) => {
  const router = useRouter()

  // Handle language change
  const changeLanguage = useCallback(
    (newLocale: Locale) => {
      if (locale !== newLocale) {
        const url = '/'
        router.push(url, url, {
          locale: newLocale,
        })
      }
    },
    [locale, router],
  )

  return (
    <LanguageContext.Provider
      value={{
        locale,
        changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
