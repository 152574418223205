import { contrastColor } from 'contrast-color'

import { type SanityThemeSettings } from '@data/sanity/queries/types/settings'
import { colors } from './tailwind'

interface HexToRgbOptions {
  readonly format?: 'object' | 'array' | 'css' | 'css-variable'
  readonly alpha?: number
}

interface RgbaObject {
  red: number
  green: number
  blue: number
  alpha: number
}

type RgbaTuple = [red: number, green: number, blue: number, alpha: number]

const hexCharacters = 'a-f\\d'
const match3or4Hex = `#?[${hexCharacters}]{3}[${hexCharacters}]?`
const match6or8Hex = `#?[${hexCharacters}]{6}([${hexCharacters}]{2})?`
const nonHexChars = new RegExp(`[^#${hexCharacters}]`, 'gi')
const validHexSize = new RegExp(`^${match3or4Hex}$|^${match6or8Hex}$`, 'i')

export const hexToRgb = (
  hex: string,
  { format, alpha }: HexToRgbOptions = {},
) => {
  if (
    typeof hex !== 'string' ||
    nonHexChars.test(hex) ||
    !validHexSize.test(hex)
  ) {
    return
  }

  hex = hex.replace(/^#/, '')
  let alphaFromHex = 1

  if (hex.length === 8) {
    alphaFromHex = Number.parseInt(hex.slice(6, 8), 16) / 255
    hex = hex.slice(0, 6)
  }

  if (hex.length === 4) {
    alphaFromHex = Number.parseInt(hex.slice(3, 4).repeat(2), 16) / 255
    hex = hex.slice(0, 3)
  }

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }

  const number = Number.parseInt(hex, 16)
  const redValue = number >> 16
  const greenValue = (number >> 8) & 255
  const blueValue = number & 255
  const alphaValue = typeof alpha === 'number' ? alpha : alphaFromHex

  switch (format) {
    case 'array': {
      return [redValue, greenValue, blueValue, alphaValue] as RgbaTuple
    }

    case 'css': {
      const alphaString =
        alphaValue === 1 ? '' : ` / ${Number((alphaValue * 100).toFixed(2))}%`
      return `rgb(${redValue} ${greenValue} ${blueValue}${alphaString})`
    }

    case 'css-variable': {
      return `${redValue} ${greenValue} ${blueValue}`
    }

    default: {
      return {
        red: redValue,
        green: greenValue,
        blue: blueValue,
        alpha: alphaValue,
      } as RgbaObject
    }
  }
}

export const getThemeBackgroundColor = (
  theme?: SanityThemeSettings,
  defaultBackgroundColor = colors.gray.dark,
) => {
  switch (theme?.theme) {
    case 'light': {
      return colors.gray.light
    }

    case 'dark': {
      return colors.gray.dark
    }

    case 'custom': {
      return theme?.color ?? defaultBackgroundColor
    }

    default: {
      return defaultBackgroundColor
    }
  }
}

export const getIsDarkTheme = (backgroundColor: string) => {
  const color = contrastColor({
    bgColor: backgroundColor,
  }) as '#000000' | '#FFFFFF'

  return color !== '#000000'
}

export const getThemeTextColor = (backgroundColor: string) => {
  const isDarkTheme = getIsDarkTheme(backgroundColor)

  return isDarkTheme ? colors.white : colors.gray.dark
}
