export type Locale = 'en'

export const defaultLocale: Locale = 'en'

export const localeNames: Record<Locale, string> = {
  en: 'English',
}

/**
 * Gets all locales.
 */
export const getAllLocales = () => ['en'] as Locale[]
