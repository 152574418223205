import { v4 as uuidv4 } from 'uuid'

/**
 * Parses JSON string into an object.
 */
export const parseJson = (json: string): Record<string, unknown> => {
  try {
    return JSON.parse(json)
  } catch (_) {
    return {}
  }
}

/**
 * Generates a random string based on UUID.
 */
export const getRandomString = () => Buffer.from(uuidv4()).toString('base64')
