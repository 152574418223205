import { type ReactNode, createContext, useCallback, useState } from 'react'

import { type SanityThemeSettings } from '@data/sanity/queries/types/settings'

interface SiteContextProps {
  theme: SanityThemeSettings
  isRouteChanging: boolean
  toggleIsRouteChanging: (newState: boolean) => void
}

interface SiteContextProviderProps {
  theme: SanityThemeSettings
  children: ReactNode
}

export const SiteContext = createContext<SiteContextProps>({
  theme: {
    theme: 'dark',
  },
  isRouteChanging: false,
  toggleIsRouteChanging: () => null,
})

export const SiteContextProvider = ({
  theme,
  children,
}: SiteContextProviderProps) => {
  // State variables
  const [isRouteChanging, setIsRouteChanging] = useState<boolean>(false)

  // Update callbacks
  const toggleIsRouteChanging = useCallback(
    (newState: boolean) => setIsRouteChanging(newState),
    [],
  )

  return (
    <SiteContext.Provider
      value={{
        theme,
        isRouteChanging,
        toggleIsRouteChanging,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
